<template>
  <div class="pa-4 grey lighten-5">
    <v-tabs v-model="tab" @change="getEmail()" class="grey lighten-5">
      <v-tab>
        {{ $t("app.student") }}
      </v-tab>
      <v-tab>
        {{ $t("app.teacher_or_staff") }}
      </v-tab>
    </v-tabs>

    <v-row no-gutters>
      <v-col cols="12" sm="7">
        <v-card class="px-4 py-2" flat>
          <div v-if="tab == 0">
            <v-radio-group
              v-model="filter_student"
              row
              hide-details
              mandatory
              @change="getEmail()"
            >
              <v-radio
                :label="$i18n.t('format_email.applied')"
                value="MAIL_PPDB_APPLIED_STUDENT"
              ></v-radio>
              <v-radio
                :label="$i18n.t('format_email.accept')"
                value="MAIL_PPDB_ACCEPT_STUDENT"
              ></v-radio>
              <v-radio
                :label="$i18n.t('format_email.reject')"
                value="MAIL_PPDB_REJECT_STUDENT"
              ></v-radio>
            </v-radio-group>
          </div>
          <div v-if="tab == 1">
            <v-radio-group
              v-model="filter_teacher"
              row
              hide-details
              mandatory
              @change="getEmail()"
            >
              <v-radio
                :label="$i18n.t('format_email.applied')"
                value="MAIL_PPDB_APPLIED_EMPLOYEE"
              ></v-radio>
              <v-radio
                :label="$i18n.t('format_email.accept')"
                value="MAIL_PPDB_ACCEPT_EMPLOYEE"
              ></v-radio>
              <v-radio
                :label="$i18n.t('format_email.reject')"
                value="MAIL_PPDB_REJECT_EMPLOYEE"
              ></v-radio>
            </v-radio-group>
          </div>
          <v-divider class="my-3"></v-divider>
          <v-progress-linear
            v-if="loading"
            color="primary"
            indeterminate
            rounded
            height="4"
          ></v-progress-linear>
          <FormatEmailForm
            :loading="loading"
            :filter="filter"
            :initialValue="description"
            @getDescription="data => (description = data)"
          />
        </v-card>
      </v-col>
      <v-col cols="12" sm="5">
        <v-card class="px-4 py-2" flat>
          <FormatEmailView
            :tab="tab"
            :description="description"
            :title="title"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormatEmailForm from "./FormatEmailForm.vue";
import FormatEmailView from "./FormatEmailView.vue";
import { get_email_format } from "@/api/admin/settings/formatEmail";

export default {
  components: {
    FormatEmailForm,
    FormatEmailView
  },
  data() {
    return {
      tab: 0,
      filter_student: null,
      filter_teacher: null,
      title: "",
      description: "",
      loading: false
    };
  },
  computed: {
    filter() {
      if (this.tab == 0) {
        return this.filter_student;
      } else {
        return this.filter_teacher;
      }
    }
  },
  mounted() {
    this.getEmail();
  },
  methods: {
    printTitle() {
      let title = "";
      if (this.tab == 0) {
        switch (this.filter_student) {
          case "MAIL_PPDB_APPLIED_STUDENT":
            title = `Terima kasih telah melakukan pendaftaran secara online`;
            break;
          case "MAIL_PPDB_ACCEPT_STUDENT":
            title = `Selamat Anda Diterima Sebagai Siswa Di Sekolah <br> <b>${this.$store.getters.g_school.about.name}</b>`;
            break;
          case "MAIL_PPDB_REJECT_STUDENT":
            title =
              "Mohon Maaf Anda Belum Dapat Bergabung Bersama Kami Saat Ini";
            break;
        }
      } else {
        switch (this.filter_teacher) {
          case "MAIL_PPDB_APPLIED_EMPLOYEE":
            title = `Terima kasih telah melakukan pendaftaran secara online`;
            break;
          case "MAIL_PPDB_ACCEPT_EMPLOYEE":
            title = `Selamat Anda Diterima Sebagai Guru / Staff Di Sekolah <br> <b>${this.$store.getters.g_school.about.name}</b>`;
            break;
          case "MAIL_PPDB_REJECT_EMPLOYEE":
            title =
              "Mohon Maaf Anda Belum Dapat Bergabung Bersama Kami Saat Ini";
            break;
        }
      }
      return title;
    },
    getEmail() {
      this.description = "";
      this.title = "";
      this.loading = true;
      get_email_format({ module: this.filter })
        .then(res => {
          if (res.data.code) {
            this.description = res.data.data.template;
            console.log(this.printTitle);
            this.title = this.printTitle();
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    }
  }
};
</script>
