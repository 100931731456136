<template>
  <div>
    <quill-editor
      ref="myQuillEditor"
      v-model="description"
      :options="editorOption"
    />
    <div class="mt-2 text-right">
      <v-btn
        :loading="loadingSave"
        class="gradient-primary"
        dark
        @click="saveEmail"
        >{{ $t("app.save") }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { update_email_format } from "@/api/admin/settings/formatEmail";

import { quillEditor, Quill } from "vue-quill-editor";
import { ImageExtend, QuillWatch } from "quill-image-extend-module";
import ImageResize from "quill-image-resize-module";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Quill.register("modules/ImageExtend", ImageExtend);
Quill.register("modules/ImageResize", ImageResize);

export default {
  props: {
    initialValue: String,
    filter: String,
    loading: Boolean
  },
  components: {
    quillEditor
  },
  data() {
    return {
      description: "",
      loadingSave: false,
      editorOption: {
        modules: {
          ImageResize: {},
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["link"]
            ],
            handlers: {
              image: function() {
                QuillWatch.emit(this.quill.id);
              }
            }
          }
        }
      }
    };
  },
  watch: {
    description(newVal) {
      this.$emit("getDescription", newVal);
    },
    initialValue(newVal) {
      this.description = newVal;
    }
  },
  methods: {
    saveEmail() {
      this.loadingSave = true;
      update_email_format({
        module: this.filter,
        template: this.description
      })
        .then(res => {
          this.$store.commit("CALL_SNACKBAR", {
            msg: res.data.message,
            color: res.data.code ? "success" : "error"
          });
          this.loadingSave = false;
        })
        .catch(() => (this.loadingSave = false));
    }
  }
};
</script>
