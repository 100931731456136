<template>
  <div
    class="grey lighten-5"
    style="box-sizing: border-box; width: 100%; padding: 5px;"
  >
    <v-row no-gutters justify="center" align="center" class="py-4">
      <div class="text-center">
        <img style="width: 30px" src="@/assets/svg/sistesi.svg" />
        <p>
          Hallo {{ tab == 0 ? $t("app.student") : $t("app.teacher_or_staff") }}
        </p>
        <p>
          <span v-html="title"></span>
        </p>
      </div>
      <div class="text-center">
        <v-card flat class="pa-4">
          <p>
            <img :src="email" style="width: 65px" class="my-2" />
          </p>
          <div
            v-html="description"
            id="description-email"
            style="max-width: 100vw; font-size: 0.9em;"
          ></div>
          <v-divider class="py-2"></v-divider>
          <p>Untuk login diweb sekolah kami, klik tombol dibawah ini</p>
          <v-btn class="gradient-primary" dark small depressed>Login</v-btn>
        </v-card>

        <v-card flat class="pa-4 mt-2">
          <p>
            Dapatkan Aplikasi <b>{{ app_name }}</b>
          </p>
          <p>
            Jika anda sudah mendownload anda bisa login dengan<br />
            akun yang sudah anda buat
          </p>
        </v-card>

        <div class="mt-4">
          <img
            src="https://sistesi.s3-ap-southeast-1.amazonaws.com/mistar-assets/PNG/tw.png"
            style="height:30px;width:30px"
            class="mr-2"
          />
          <img
            src="https://sistesi.s3-ap-southeast-1.amazonaws.com/mistar-assets/PNG/ig.png"
            style="height:30px;width:30px"
            class="mr-2"
          />
          <img
            src="https://sistesi.s3-ap-southeast-1.amazonaws.com/mistar-assets/PNG/YT.png"
            style="height:30px;width:30px"
            class="mr-2"
          />
          <img
            src="https://sistesi.s3-ap-southeast-1.amazonaws.com/mistar-assets/PNG/FB.png"
            style="height:30px;width:30px"
            class="mr-2"
          />
        </div>
        <div class="mt-6">
          <div>Copyright 2020</div>
          <img
            src="https://sistesi.s3-ap-southeast-1.amazonaws.com/mistar-assets/PNG/exi.png"
            style="height:30px;width:auto"
          />
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tab: Number,
    title: String,
    description: String
  },
  data() {
    return {
      app_name: process.env.VUE_APP_APPLICATION_NAME,
      email:
        "https://sistesi.s3-ap-southeast-1.amazonaws.com/mistar-assets/PNG/email_mistar.png"
    };
  }
};
</script>

<style lang="scss">
#description-email {
  p {
    margin-bottom: 2px !important;
  }
}
</style>
