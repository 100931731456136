import request from "@/utils/request";

export function get_email_format(data) {
  return request({
    method: "post",
    url: "mail/get_template",
    data: data
  });
}

export function update_email_format(data) {
  return request({
    method: "post",
    url: "mail/update_template",
    data: data
  });
}
